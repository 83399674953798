html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: white;
  background: black;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

#screen {
  background: darkolivegreen;
  max-width: 100%;
  max-height: 100%;
}

/* HUD */
#crosshair {
  position: absolute;
  top: 50%;
  right: 50%;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: darkred;
}

/* DEBUG */
#fps-counter {
  position: absolute;
  top: 10px;
  right: 10px;
}

#camera-position {
  position: absolute;
  top: 10px;
  left: 10px;
}

#camera-rotation {
  position: absolute;
  top: 28px;
  left: 10px;
}

#block-count {
  position: absolute;
  top: 46px;
  left: 10px;
}
